import LanguageSelector from "@/components/languageSelector/languageSelector";
import NavigationBrand from "@/components/navigation/navigationBrand";
import NavigationDesktopItems from "@/components/navigation/navigationDesktop/navigationDesktopItems";
import NavigationDesktopStyle from "@/components/navigation/navigationDesktop/navigationDesktopStyle";
import SearchBar from "@/components/search/searchBar/searchBar";
import SearchButton from "@/components/search/searchButton/searchButton";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import {
  hideSearchBar,
  showSearchBar,
} from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { NavigationElement } from "@/types/navigation";
import clsx from "clsx";

interface NavigationDesktopProps {
  navigation: NavigationElement;
}

export default function NavigationDesktop(props: NavigationDesktopProps) {
  const dispatch = useAppDispatch();
  const searchBarActive = useAppSelector(
    (state) => state.general.navigation.searchBarActive
  );
  return (
    <>
      <div className={clsx("navigation-desktop", searchBarActive && "search")}>
        {props.navigation.logo && (
          <div className="navigation-desktop-logo">
            <NavigationBrand
              logo={props.navigation.logo}
              url={props.navigation.logoUrl}
            />
          </div>
        )}
        <nav>
          <NavigationDesktopItems
            pages={props.navigation.pages}
            allItems={globalConfig?.navigation.showAllNavItems}
          />
        </nav>
        <div className="navigation-desktop-action-group">
          {globalConfig?.search.searchEnabled && (
            <SearchButton
              hidden={searchBarActive}
              toggleSearchBar={() => dispatch(showSearchBar())}
            />
          )}
          {globalConfig?.multilanguage.languageSwitchEnabled && (
            <LanguageSelector />
          )}
        </div>
        <div className={"navigation-search"}>
          <SearchBar
            onSubmit={() => dispatch(hideSearchBar())}
            toggleSearchBar={() => dispatch(hideSearchBar())}
          />
        </div>
      </div>
      <NavigationDesktopStyle />
    </>
  );
}
