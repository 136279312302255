import CmsPageSeoSettings from "@/components/cms/cmsPage/cmsPageSeoSettings";
import CmsPageSettings from "@/components/cms/cmsPage/cmsPageSettings";
import CmsTabs from "@/components/cms/cmsTabs/cmsTabs";
import PbGenericModal from "@/components/genericModal/pbGenericModal";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  initEditDataAction,
  resetEditDataAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsCreatePageThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { hideCreatePageModalAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { showLoadingOverlayAction } from "@/store/slices/general/generalSlice";
import { redirectThunk } from "@/store/slices/general/generalThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function CmsPageCreateModal() {
  const tCms = useCmsTranslation();
  const showCreatePageModal = useAppSelector(
    (state) => state.cmsGeneral.showCreatePageModal
  );
  const [tabValue, setTabValue] = useState<string>("0");

  const dispatch = useAppDispatch();
  const router = useRouter();
  useEffect(() => {
    if (showCreatePageModal) {
      dispatch(
        initEditDataAction({ name: "", hasNavigation: true, hasFooter: true })
      );
    } else {
      dispatch(resetEditDataAction());
    }
  }, [showCreatePageModal, dispatch]);
  const handleCreatePage = async () => {
    try {
      const createdPage = await dispatch(cmsCreatePageThunk());
      createToastSuccess(tCms("pageCreationSuccess"));
      dispatch(showLoadingOverlayAction(tCms("creatingPage")));
      dispatch(hideCreatePageModalAction());
      dispatch(
        redirectThunk(
          () =>
            router.push("/" + createdPage.url + "?edit=true", undefined, {
              locale: createdPage.locale,
            }),
          "/" + createdPage.url + "?edit=true"
        )
      );
    } catch (error) {
      createToastError(tCms("pageCreationError"));
    }
  };
  return (
    <PbGenericModal
      className="cms-modal with-tabs cms-page-create-modal"
      open={showCreatePageModal}
      title={tCms("page-createPage")}
      muiDialogProps={{ fullWidth: true }}
      onConfirmClick={handleCreatePage}
      onCancelClick={() => {
        setTabValue("0");
        dispatch(hideCreatePageModalAction());
      }}
      muiDialogContentSxProps={{
        padding: 0,
      }}
    >
      <CmsTabs>
        <TabContext value={tabValue}>
          <TabList
            className="tab-list"
            aria-label={tCms("selectTab")}
            onChange={(event: any, newValue: string) => {
              setTabValue(newValue);
            }}
          >
            <Tab label={tCms("page-pageInformations")} value="0" />
            <Tab label={tCms("seo")} value="1" />
          </TabList>
          <TabPanel value="0">
            <CmsPageSettings />
          </TabPanel>
          <TabPanel value="1">
            <CmsPageSeoSettings />
          </TabPanel>
        </TabContext>
      </CmsTabs>
    </PbGenericModal>
  );
}
