import ResponseResult from "@/types/classes/ResponseResult";
import { getStrapiURL } from "@/utils/api";
import {
  axiosGetRequestServerSide,
  handleRequestErrorServerSide,
} from "@/utils/axiosServerUtil";

export let cachedAt: string = "";

export let navigationsCache: { [locale: string]: [] };
export let dynamicListsCache: { [locale: string]: [] };
export let globalConfigCache: any = {};
export let globalCssCache: any = "";
export let ceSettingsCache: any = [];
export let customBodyScriptsCache: any = "";
export let customHeadScriptsCache: any = "";

export const setCachedAt = (cachedAtParam: string) => {
  cachedAt = cachedAtParam;
};
export const setNavigationsCache = (navigations: any) => {
  navigationsCache = navigations;
};
export const setDynamicListsCache = (dynamicLists: any) => {
  dynamicListsCache = dynamicLists;
};
export const setGlobalCssCache = (globalCssParam: any) => {
  globalCssCache = globalCssParam;
};
export const setGlobalConfigCache = (globalConfigParam: any) => {
  globalConfigCache = globalConfigParam;
};
export const setCeSettingsCache = (ceSettingsParam: any) => {
  ceSettingsCache = ceSettingsParam;
};
export const setCustomBodyScriptsCache = (customBodyScriptsParam: any) => {
  customBodyScriptsCache = customBodyScriptsParam;
};
export const setCustomHeadScriptsCache = (customHeadScriptsParam: any) => {
  customHeadScriptsCache = customHeadScriptsParam;
};

export const isCacheExisting = (): boolean => {
  if (cachedAt !== "") {
    return true;
  }
  return false;
};

export const setNewCache = (
  cachedAt: string,
  globalConfigResponse: any,
  navigationsResponse: any,
  globalCssResponse: any,
  dynamicListsResponse: any,
  ceSettingsResponse: any,
  headScriptsResponse: any,
  bodyScriptsResponse: any
) => {
  setCachedAt(cachedAt);
  setGlobalConfigCache(globalConfigResponse);
  setNavigationsCache(navigationsResponse);
  setGlobalCssCache(globalCssResponse);
  setDynamicListsCache(dynamicListsResponse);
  setCeSettingsCache(ceSettingsResponse);
  setCustomHeadScriptsCache(headScriptsResponse);
  setCustomBodyScriptsCache(bodyScriptsResponse);
  return getCurrentServerCache();
};

export const getCurrentServerCache = () => {
  return {
    cachedAt,
    navigationsCache,
    dynamicListsCache,
    globalConfigCache,
    globalCssCache,
    ceSettingsCache,
    customBodyScriptsCache,
    customHeadScriptsCache,
  };
};

export const getPbSystem = async () => {
  const result: ResponseResult<any> = await axiosGetRequestServerSide(
    getStrapiURL(`/pabu/pbsystem`)
  );
  if (result.success) {
    return result.data;
  } else {
    handleRequestErrorServerSide(
      result.error,
      `[getPbSystem] could not fetch pbsystem`
    );
    return null;
  }
};

export const getSystemFileContent = (
  pbSystem: any,
  systemFilename: string,
  type: "json" | "string",
  localized?: boolean
) => {
  if (type === "json") {
    try {
      if (localized) {
        if (!pbSystem.systemFiles[systemFilename]) {
          return null;
        }
        const localizedObject: { [localeKey: string]: any } = {};
        const locales = Object.keys(pbSystem.systemFiles[systemFilename]);
        for (const localeKey of locales) {
          localizedObject[localeKey] = JSON.parse(
            pbSystem.systemFiles[systemFilename][localeKey]
          );
        }
        return localizedObject;
      }
      return JSON.parse(pbSystem.systemFiles[systemFilename]);
    } catch (error) {
      console.log("[getSystemFileContent]");
      console.log(error);
      return null;
    }
  }
  return pbSystem.systemFiles[systemFilename];
};
