import { SelectChangeEvent } from "@mui/material";
import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import React, { useState } from "react";
import useAvailableLocales from "@/hooks/useAvailableLocales";
import { useRouter } from "next/router";
import { useAppDispatch } from "@/store/store";
import { updateAttributeAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";

export default function CmsMediaManagerLanguageSelector() {
  const availableLocales = useAvailableLocales();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    router.locale
  );
  return (
    <PbDropdown
      dropdownList={availableLocales}
      onChange={(e: SelectChangeEvent) => {
        dispatch(
          updateAttributeAction({
            attributePath: "mediaManagerModal.locale",
            value: e.target.value,
          })
        );
        setSelectedLanguage(e.target.value);
      }}
      value={selectedLanguage}
      itemNameKey={"name"}
      itemValueKey={"value"}
    ></PbDropdown>
  );
}
