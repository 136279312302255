import { globalConfig } from "@/services/globalConfig/globalConfigService";
import {
  lowerBreakpointDesktopPx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";

export interface FooterSpaceProps {
  settings: {
    spaceX: number;
  };
}

export default function FooterSpace(props: FooterSpaceProps) {
  const scalingfactorSpaceXMobile =
    globalConfig?.layout?.scalingfactorSpaceX?.mobile ?? 0;
  const scalingfactorSpaceXTablet =
    globalConfig?.layout?.scalingfactorSpaceX?.tablet ?? 0;
  const scalingfactorSpaceX2kplus =
    globalConfig?.layout?.scalingfactorSpaceX?.wqhd ?? 0;

  const spaceXrow =
    props.settings.spaceX - 8 < 0 ? 0 : props.settings.spaceX - 8; // 8, 8+ or 0
  const spaceXcontainer =
    props.settings.spaceX !== 0
      ? 8 - props.settings.spaceX > 0
        ? 8 - props.settings.spaceX
        : 8
      : 0; // 0, 1-7 or 8

  const breakPointMobile = globalConfig?.responsive?.breakpoints?.mobile ?? 768;
  const breakPointDesktop =
    globalConfig?.responsive?.breakpoints?.mobile ?? 2560;
  const breakPointWqhd = globalConfig?.responsive?.breakpoints?.mobile ?? 3840;

  return (
    <style jsx global>{`
      .footer-space {
        /* Container Mobile */
        padding-left: ${spaceXcontainer *
        scalingfactorSpaceXMobile}px !important;
        padding-right: ${spaceXcontainer *
        scalingfactorSpaceXMobile}px !important;
        /* Container Tablet */
        @media (min-width: ${breakPointMobile}px) {
          padding-left: ${spaceXcontainer *
          scalingfactorSpaceXTablet}px !important;
          padding-right: ${spaceXcontainer *
          scalingfactorSpaceXTablet}px !important;
        }
        /* Container Desktop */
        @media (min-width: ${breakPointDesktop}px) {
          padding-left: ${spaceXcontainer}px !important;
          padding-right: ${spaceXcontainer}px !important;
        }
        /* Container 2k Desktop */
        @media (min-width: ${breakPointWqhd}px) {
          padding-left: ${spaceXcontainer *
          scalingfactorSpaceX2kplus}px !important;
          padding-right: ${spaceXcontainer *
          scalingfactorSpaceX2kplus}px !important;
        }
        & > div {
          padding-left: ${spaceXrow * scalingfactorSpaceXMobile}px !important;
          padding-right: ${spaceXrow * scalingfactorSpaceXMobile}px !important;

          @media (min-width: ${lowerBreakpointTabletPx()}) {
            padding-left: ${spaceXrow * scalingfactorSpaceXTablet}px !important;
            padding-right: ${spaceXrow *
            scalingfactorSpaceXTablet}px !important;
          }

          @media (min-width: ${lowerBreakpointDesktopPx()}) {
            padding-left: ${spaceXrow}px !important;
            padding-right: ${spaceXrow}px !important;
          }

          @media (min-width: ${lowerBreakpointWqhdPx()}) {
            padding-left: ${spaceXrow * scalingfactorSpaceX2kplus}px !important;
            padding-right: ${spaceXrow *
            scalingfactorSpaceX2kplus}px !important;
          }
        }
      }
    `}</style>
  );
}
