import Head from "next/head";
import { useRef } from "react";

type GlobalStyleProps = {
  globalCssString: string;
};


/**
 * Contains the globally applied CSS
 * 
 * @param {Object} props
 * @param {string} props.globalCssString the global css generated by strapi
 */
export default function GlobalStyle(props: GlobalStyleProps) {
  const generatedStyles = useRef(null);

  return (
    <>
      <style jsx global>
        {`
          /* Small CSS-Reset: https://piccalil.li/blog/a-modern-css-reset/  */
          /* Box sizing rules */
          *,
          *::before,
          *::after {
            box-sizing: border-box;
          }

          a {
            text-decoration: none;
          }

          /* Remove default margin */
          body,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          figure,
          blockquote,
          dl,
          dd {
            margin: 0;
          }

          /*
          .cms-style-autocomplete {
            color: #fff;
            .MuiOutlinedInput-root {
              border: none;
              background-color: #105f8d !important;
              color: white;
              border-radius: 0;
            }
            .MuiSvgIcon-root {
              color: white;
            }
            .MuiSelect-select {
              padding-left: 1.5rem;
              padding-top: 0.7rem;
              padding-bottom: 0.7rem;
            }
            .MuiOutlinedInput-notchedOutline {
              border: none;
            }
          }
          */

          /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
          ul[role="list"],
          ol[role="list"] {
            list-style: none;
          }

          /* Set core root defaults */
          html:focus-within {
            scroll-behavior: smooth;
          }

          html {
            height: 100% !important;
            /* Causing scrolling on modal interactions: */
            /* overflow: auto !important; */
          }

          /* Set core body defaults */
          body {
            height: 100% !important;
            min-height: 100vh;
            text-rendering: optimizeSpeed;
            line-height: 1.5;
            position: relative;
          }

          /* A elements that don't have a class get default styles */
          a:not([class]) {
            text-decoration-skip-ink: auto;
          }

          /* Make images easier to work with */
          img,
          picture {
            max-width: 100%;
            display: block;
          }

          /* Inherit fonts for inputs and buttons */
          input,
          button,
          textarea,
          select {
            font: inherit;
          }

          /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
          @media (prefers-reduced-motion: reduce) {
            html:focus-within {
              scroll-behavior: auto;
            }

            *,
            *::before,
            *::after {
              animation-duration: 0.01ms !important;
              animation-iteration-count: 1 !important;
              transition-duration: 0.01ms !important;
              scroll-behavior: auto !important;
            }
          }

          :root {
            --pb-cms-color: #198fd9;
            --pb-cms-color-hover: #1565c0;
            /* Use if you want to set "black"/"white"/"transparent" as color. */
            --pb-black: #000000;
            --pb-white: #ffffff;
            --pb-transparent: #00000000;
            --pb-rounded-input-border-radius: 6px;
          }

          .svg-fill-white {
            fill: white;
          }

          .svg-fill-black {
            fill: black;
          }

          .svg-fill-red {
            fill: red;
          }
          .svg-fill-green {
            fill: green;
          }

          /* autogrowing inputs / textareas */
          /* see https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
          .grow-wrap {
            display: grid;
            &::after {
              content: attr(data-replicated-value) " ";
              white-space: pre-wrap;
              visibility: hidden;
            }
            > textarea {
              resize: none;
              overflow: hidden;
            }
            > textarea,
            ::after {
              font-size: inherit;
              padding: 0;
              grid-area: 1 / 1 / 2 / 2;
            }
          }
          .heading.grow-wrap {
            position: relative;
          }

          /* RTE Quill style like MUI input style (border only) */
          .ql-toolbar {
            border-top-left-radius: var(--pb-rounded-input-border-radius);
            border-top-right-radius: var(--pb-rounded-input-border-radius);
          }

          .ql-container {
            border-bottom-left-radius: var(--pb-rounded-input-border-radius);
            border-bottom-right-radius: var(--pb-rounded-input-border-radius);
          }

          /* -------- MUI --------*/
          /* Material Ui Input styling */
          .MuiOutlinedInput-root {
            height: 38px !important;
            padding-right: 0px !important;
            border-radius: var(--pb-rounded-input-border-radius) !important;

            .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
              margin-right: 12px;
            }

            input {
              padding-left: 1.5rem;
              padding-top: 10px;
              padding-bottom: 9px;
              border-radius: var(--pb-rounded-input-border-radius);
            }

            .MuiOutlinedInput-notchedOutline {
              border-color: #d2d4da;
              border-radius: var(--pb-rounded-input-border-radius);
              overflow: visible;
              border-color: white;
              border-width: 1px !important;
            }
            .MuiOutlinedInput-notchedOutline::after {
              border-radius: calc(var(--pb-rounded-input-border-radius) + 1px);
              padding: 0px;
              border: 1px solid #d2d4da;
              position: absolute;
              content: "";
              top: -2px;
              left: -2px;
              bottom: -2px;
              right: -2px;
            }
            fieldset {
              top: 0px;
              legend {
                display: none;
              }
            }
          }

          /* MUI  input error styling */
          .MuiOutlinedInput-root.cms-input-error {
            .MuiOutlinedInput-notchedOutline {
              border-color: red !important;
              border-radius: 6px;
              border-width: 1px !important;
            }
            .MuiOutlinedInput-notchedOutline::after {
              border-radius: calc(var(--pb-rounded-input-border-radius) + 1px);
              padding: 0px;
              border: 1px solid #d2d4da;
              position: absolute;
              content: "";
              top: -2px;
              left: -2px;
              bottom: -2px;
              right: -2px;
            }
          }

          /* error icon color */
          .cms-input-error-icon {
            padding-bottom: 5px;
          }

          /* error text div */
          .cms-input-error-msg {
            padding-top: 7px;
            padding-bottom: 3px;
            color: red;
            font-weight: 400;
            font-style: normal;
            font-size: 10px;
          }

          /* MUI Input hover */
          .MuiOutlinedInput-root:hover {
            .MuiOutlinedInput-notchedOutline {
              border-color: #000;
              border-width: 1px !important;
            }
            .MuiOutlinedInput-notchedOutline::after {
              border-radius: calc(var(--pb-rounded-input-border-radius) + 1px);
              padding: 0px;
              border: 1px solid #d2d4da;
              position: absolute;
              content: "";
              top: -2px;
              left: -2px;
              bottom: -2px;
              right: -2px;
            }
          }

          /* MUI Input disabled hover */
          .MuiOutlinedInput-root.Mui-disabled:hover {
            input {
              cursor: not-allowed !important;
            }
            .MuiOutlinedInput-notchedOutline {
              border-color: #f2f2f2;
              border-width: 1px !important;
            }
          }

          /* MUI Input focused */
          .MuiOutlinedInput-root.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: #5061f8;
              border-width: 1px !important;
            }
          }

          /* MUI Input multiline and textarea*/
          .MuiOutlinedInput-root.MuiInputBase-multiline {
            height: auto !important;
            padding: 6px;

            textarea {
              padding-left: 0.6rem;
              padding-right: 0.6rem;
            }
          }

          /* MUI Autocomplete multiple */
          .MuiAutocomplete-root.cms-autocomplete-multiple {
            .MuiOutlinedInput-root {
              height: auto !important;
            }
          }

          /* MUI Autocomplete dropdown listbox container (behind the ul) */
          .MuiAutocomplete-popper {
            margin-top: 6px !important;
            .MuiPaper-root.MuiAutocomplete-paper {
              background-color: transparent !important;
              border-radius: var(--pb-rounded-input-border-radius);
            }
          }

          /* MUI Autocomplete dropdown listbox ul*/
          [id^="cms-autocomplete-id"].MuiAutocomplete-listbox {
            background-color: #fff;
            border: 1px solid #d2d4da;
            border-radius: var(--pb-rounded-input-border-radius);
            padding: 6px;
            color: #333333;

            .MuiListSubheader-root {
              border-radius: var(--pb-rounded-input-border-radius);
            }
            .Mui-focused {
              background-color: #f8f9fa !important;
            }
            li {
              border-bottom: none;
              font-size: 14px;

              .private-indicator {
                margin-left: 6px;
              }

              .default-page-indicator {
                margin-left: 6px;
              }
            }
          }

          /* Overrides Mui select font size */
          .pb-mui-select.MuiSelect-select {
            font-size: 14px;
          }

          /* Overrides Text-Pointer of non-searchable Dropdowns */
          .MuiOutlinedInput-root {
            /* New dropdown overlay cursor fix */
            .MuiModal-root.MuiPopover-root {
              /* cursor while the dropdown is open (while cursor is outside the dropdown)
              without this cursor: pointer the cursor will be the text cursor */
              cursor: pointer;
            }
          }

          /* New dropdown list item style*/
          .MuiPopover-root .MuiPaper-root,
          .MuiOutlinedInput-root .MuiModal-root.MuiPopover-root .MuiPaper-root {
            border-radius: var(--pb-rounded-input-border-radius);
            ul {
              background-color: #fff;
              //outline: 1px solid #d2d4da;
              border: 1px solid #d2d4da;
              border-radius: var(--pb-rounded-input-border-radius);
              padding: 6px;
              color: #333333;
              .Mui-focused {
                background-color: #f8f9fa !important;
              }
              li {
                border-bottom: none;
                font-size: 14px;

                .private-indicator {
                  margin-left: 6px;
                }

                .default-page-indicator {
                  margin-left: 6px;
                }
              }
            }
          }

          /* Input label (this class can also be used on non MUI elements) */
          .cms-input-label {
            word-wrap: break-all;
            word-break: break-word;
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 7px;
            .cms-input-mandatory {
              margin-left: 3px;
              color: red;
            }
          }

          /* MUI Switch (Toggleswitch / checkbox) */

          /* MUI Switch (not checkbox)*/
          .MuiSwitch-root.MuiSwitch-sizeMedium {
            margin-left: -10px;

            /* MUI Switch checked style */
            .MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked {
              color: #5061f7;
              .MuiSwitch-track {
                background-color: #c3c8fa;
              }
            }
          }

          /* MUI Checkbox (not switch) */
          .MuiCheckbox-root {
            // the check box is vertically centered next to an input
            margin-top: -5px;
            margin-left: -13px;

            svg {
              opacity: 0.8; // 0.5 -> 0.8 being closer to configured inputBgColor
            }
            /* you have to move the actual input because of the margin above
               without this the click zone is offset */
            input {
              margin-left: 11px;
              margin-top: 11px;
            }
          }

          /* MUI Checkbox checked style */
          .cms-checkbox .MuiCheckbox-root.Mui-checked {
            svg {
              color: #5061f7 !important;
              opacity: 1;
            }
          }

          /* MUI Slider (color) */
          .MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium {
            color: #5061f7;
          }

          /* untouched mui css while adding new style */
          .Mui-form {
            .MuiFormLabel-root {
              &.MuiInputLabel-shrink {
                transform: none;
                transform-origin: top left;
                font-size: 16px;
                line-height: 1.5;
                margin-bottom: 0;
              }
              color: darkgray;
              font-family: inherit;
              font-size: 1rem;
              &.Mui-focused {
                color: darkgray;
              }
            }
          }

          .mui-datepicker-default.MuiTextField-root {
            .MuiInput-root.MuiInputBase-root {
              padding-right: 1rem;
            }
          }

          /* end material ui */
          /* -------- MUI END --------*/

          /* general bootstrap button for adding content */
          .cms-btn-add-content {
            min-width: 125px;
            min-height: 50px;
            background-color: var(--pb-cms-color);
            border-color: #d7d7d7;
            font-weight: bold;
            margin-left: 10px;
            border-radius: 4px;
            :hover {
              opacity: 0.7;
              background-color: var(--pb-cms-color);
              border-color: #d7d7d7;
            }
            :focus {
              opacity: 0.8;
              background-color: var(--pb-cms-color);
              border-color: #d7d7d7;
            }
          }

          /* combined-bar-sticky-stuff */
          .combined-sticky-elements {
            z-index: 1020;
            position: sticky;
            top: 0px;
            .general-config-frame {
              margin-top: -2px !important;
            }
          }

          /* Content-Element-Alignment */
          .g-0 {
            .edge-to-edge-row.row {
              --bs-gutter-x: 0;
              padding-left: var(--bs-gutter-x) !important;
              padding-right: var(--bs-gutter-x) !important;
            }

            /* Use "edge-to-edge-row inner-row" inside of a col inside of .edge-to-edge-row to "recreate" bootstrap behaviour */
            .inner-row > div {
              --bs-gutter-x: 1.5rem;
            }
          }
          .save-report-files {
            text-align: left;
          }

          .cms-button.MuiButton-root {
            background-color: var(--pb-cms-color);
            color: white;
            font-size: 16px;
            text-transform: none;
            :hover {
              background-color: #1a85c7 !important;
            }
          }

          .cms-button-cancel.MuiButton-root {
            color: #fff;
            background-color: #8b8b8b;

            font-size: 16px;
            text-transform: none;
            :hover {
              background-color: #7c7c7c !important;
            }
          }

          .ql-editor {
            /* Without important this line get's overriden by ql-editor-default-styling after login as cmsUser: Causing vertical scrollbars on RT-Content. */
            /* This might be not the only issue: */
            line-height: normal !important;
            .ql-align-center {
              text-align: center;
            }
            .ql-align-right {
              text-align: right;
            }
          }

          .rotate {
            animation: rotation 1.75s infinite linear;
          }

          .rotate-slow {
            animation: rotation 2.5s infinite linear;
          }

          .rotate-fast {
            animation: rotation 1.25s infinite linear;
          }

          @keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(359deg);
            }
          }

          .hover-floating {
            transition: -webkit-transform 0.3s ease;
            transition: transform 0.3s ease;
          }

          .hover-floating:hover {
            transform: translateY(-10px);
          }

          .hover-zoom-in {
            transition: transform 0.2s ease;
          }

          .hover-zoom-in:hover {
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            transition: transform 0.3s ease;
          }

          .hover-zoom-out:hover {
            -ms-transform: scale(0.85);
            transform: scale(0.85);
          }

          .hover-shadow {
            transition: box-shadow 0.3s;
          }
          .hover-shadow:hover {
            box-shadow: 0 0 11px rgba(33, 33, 33, 0.5);
          }

          .cms-login-form {
            height: 100vh;
          }

          .cms-login-form .cms-input-label,
          .cms-login-form h1,
          .cms-login-form input,
          .cms-login-form .MuiInputAdornment,
          .cms-login-form svg {
            color: var(--pb-cms-color) !important;
          }
        `}
      </style>

      <Head>
        <style
          ref={generatedStyles}
          id="generated-styles"
          dangerouslySetInnerHTML={{
            __html: props.globalCssString,
          }}
        />
      </Head>
    </>
  );
}
