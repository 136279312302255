import { PbIcon } from "../pbIcon/PbIcon";

export interface ImagePlaceholderProps {
  placeholderWidth: number;
  placeholderHeight: number;
  style: {};
  className?: string;
}

export const ImagePlaceholder = (props: ImagePlaceholderProps) => {
  return (
    <div className={`${props.className}`} style={props.style}>
      <PbIcon
        name={"photo-film-light"}
        className={"svg-fill-white"}
        width={props.placeholderWidth}
        height={props.placeholderHeight}
      />
    </div>
  );
};
