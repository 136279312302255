import { storeColorCss } from "@/services/ceSettings/ceSettingsService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { lowerBreakpointDesktopPx } from "@/utils/util";

export const SearchBarStyle = () => {
  return (
    <style jsx global>{`
      .search-bar {
        width: 100%;
        height: 32px;
        margin-left: auto;
        margin-right: auto;
        max-width: ${!globalConfig?.search?.searchBarMaxWidth
          ? "unset"
          : globalConfig?.search?.searchBarMaxWidth + "px"};
        font-size: 1.6rem;

        /* Default/Fallback Styling: */
        &.str-typography-unstyled {
          --pb-typography-color: var(--pb-black);
          --pb-typography-fontSize-mobile: 1.6rem;
          --pb-typography-fontSize-desktop: 1.6rem;
          --pb-typography-fontFamily: inherit;
          --pb-typography-fontWeight: 400;
          --pb-typography-lineHeight: 1.2;
        }

        .MuiOutlinedInput-root {
          background-color: ${storeColorCss(
            globalConfig?.search?.searchBarBgColor,
            "var(--pb-white)"
          )} !important;
        }
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid var(--pb-black) !important;
          border-radius: inherit;
        }

        .MuiInputBase-input {
          color: var(--pb-typography-color) !important;
          font-size: var(--pb-typography-fontSize-mobile) !important;
          font-family: var(--pb-typography-fontFamily) !important;
          font-weight: var(--pb-typography-fontWeight) !important;
          line-height: var(--pb-typography-lineHeight) !important;
        }

        .MuiInput-root.MuiInputBase-root input {
          padding-left: 0.5rem !important;
        }

        button:hover {
          background-color: transparent;
        }

        .search-suggestions-wrapper {
          position: relative;
          width: calc(100% + 2px);
          left: 0;
          margin-top: -9px;
          ul.search-suggestions {
            padding: 0;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            list-style: none;
            position: absolute;
            width: calc(100% - 2px);
            border: 1px solid var(--pb-black);
            border-top: none;
            background-color: ${storeColorCss(
              globalConfig?.search?.searchBarBgColor,
              "var(--pb-white)"
            )};
            color: var(--pb-typography-color);
            font-size: var(--pb-typography-fontSize-mobile);
            font-family: var(--pb-typography-fontFamily);
            font-weight: var(--pb-typography-fontWeight);
            line-height: var(--pb-typography-lineHeight);

            li.search-suggestion {
              cursor: pointer;
              padding-left: 0.5rem;
              padding-right: 0.5rem;
              padding-top: 0.3rem;
              padding-bottom: 0.3rem;
              word-break: break-all;
            }
            /* TBD: Does this need a setting? */
            li.search-suggestion:focus,
            li.search-suggestion:hover {
              background-color: ${storeColorCss(
                globalConfig?.search?.searchBarBgHoverColor,
                "var(--pb-white)"
              )};
              outline: none;
            }
          }
        }

        @media (min-width: ${lowerBreakpointDesktopPx()}) {
          .MuiInputBase-input {
            font-size: var(--pb-typography-fontSize-desktop) !important;
          }
          .search-suggestions-wrapper {
            ul.search-suggestions {
              font-size: var(--pb-typography-fontSize-desktop);
            }
          }
        }
      }
    `}</style>
  );
};

export default SearchBarStyle;
