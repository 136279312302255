import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import useAvailableLocales from "@/hooks/useAvailableLocales";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { cmsSaveFormThunk } from "@/store/slices/cmsEdit/cmsFormThunks";
import { cmsUpdateNavigationThunk } from "@/store/slices/cmsEdit/cmsNavigationThunks";
import { cmsSaveDraftPageContentThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { setEditModeAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import {
  disableBeforeunloadAction,
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { CONTEXT_FORM, CONTEXT_NAVIGATION, CONTEXT_PAGE } from "@/utils/constants";
import { changeLanguage } from "@/utils/localizationUtil";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import { useState } from "react";
import CmsLanguageSelectorStyle from "./cmsLanguageSelectorStyle";

export default function CmsLanguageSelector() {
  const tCms = useCmsTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    router.locale
  );
  const currentContext = useAppSelector(
    (state) => state.cmsGeneral.currentContext
  );
  const localesInSync = useAppSelector(
    (state) => state.cmsGeneral.localesStatus.localesInSync
  );
  const availableLocales = useAvailableLocales(true);
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const { showConfirmModal } = useConfirmModal(
    async () => {
      if (currentContext === CONTEXT_PAGE) {
        try {
          await dispatch(cmsSaveDraftPageContentThunk());
          dispatch(disableBeforeunloadAction());
          dispatch(
            showLoadingOverlayAction(tCms("language-languageIsBeingChanged"))
          );
          dispatch(setEditModeAction(false));
          createToastSuccess(tCms("pageSaveSuccess"));
          changeLanguage(selectedLanguage!, router, undefined, 500, true);
        } catch (err) {
          dispatch(hideLoadingOverlayAction());
          createToastError(tCms("pageUpdateError"));
        }
      } else if (currentContext === CONTEXT_NAVIGATION) {
        dispatch(disableBeforeunloadAction());
        await dispatch(cmsUpdateNavigationThunk());
        dispatch(
          showLoadingOverlayAction(tCms("language-languageIsBeingChanged"))
        );
        dispatch(setEditModeAction(false));
        createToastSuccess("navigation successfully saved");
        changeLanguage(selectedLanguage!, router, undefined, 500, true);
      } else if (currentContext === CONTEXT_FORM) {
        try {
          await dispatch(cmsSaveFormThunk());
          dispatch(disableBeforeunloadAction());
          dispatch(
            showLoadingOverlayAction(tCms("language-languageIsBeingChanged"))
          );
          dispatch(setEditModeAction(false));
          changeLanguage(selectedLanguage!, router, undefined, 500);
        } catch (error) {
          createToastError(tCms("formsUpdateError"));
          if (error) {
            createToastError(tCms(error as string));
          }
        }
      }
    },
    () => {
      dispatch(disableBeforeunloadAction());
      dispatch(
        showLoadingOverlayAction(tCms("language-languageIsBeingChanged"))
      );
      dispatch(setEditModeAction(false));
      changeLanguage(
        selectedLanguage!,
        router,
        undefined,
        100,
        currentContext === CONTEXT_PAGE
      );
    }
  );

  const handleLanguageSelectEvent = (event: SelectChangeEvent) => {
    if (!editMode) {
      dispatch(showLoadingOverlayAction());
      changeLanguage(event.target.value, router);
      return;
    }
    showConfirmModal({
      title: tCms("saveChanges"),
      content: tCms("language-changeLanguageConfirmation"),
      acceptBtnText: tCms("yes"),
      denyBtnText: tCms("no"),
      cancelBtnText: tCms("cancel"),
      showCancelBtn: true,
      icon: "triangle-exclamation-light",
    });
    setSelectedLanguage(event.target.value);
    event.target.value = router.locale!;
  };

  const isCmsLanguageSelectorDisabled = () => {
    switch (router.pathname) {
      case "/cms/forms/create":
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "10px",
          paddingRight: "5px",
        }}
        className="cms-language-selector"
      >
        <PbDropdown
          dropdownList={availableLocales}
          onChange={handleLanguageSelectEvent}
          value={router.locale}
          name="cms-language-selector"
          itemNameKey={"name"}
          itemValueKey={"value"}
          disabled={isCmsLanguageSelectorDisabled() || !localesInSync}
        ></PbDropdown>
      </Box>
      <CmsLanguageSelectorStyle />
    </>
  );
}
