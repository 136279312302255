import PbAutoComplete from "@/components/input/pbAutoComplete/pbAutoComplete";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useIsMounted from "@/hooks/useIsMounted";
import { getAllCmsPages } from "@/services/cmsService/cmsService";
import { setAllPages } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ROOT_PAGE_URL } from "@/utils/constants";
import { removeUrlAnchor, removeUrlQueryParams } from "@/utils/urlUtil";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import CmsTopBarCenterControlsStyle from "./cmsTopBarCenterControlsStyle";
/**
 * Represents the props for the CmsTopBarProps component.
 */
export interface CmsTopBarCenterControlsProps {}

/**
 * CmsInputLabel component represents a label for an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the CmsInputLabel.
 */
const CmsTopBarCenterControls = (
  props: CmsTopBarCenterControlsProps
): JSX.Element => {
  const allPages = useAppSelector((state) => state.cmsGeneral.allPages);
  const router = useRouter();
  const tCms = useCmsTranslation();

  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getSelectedPage = (): any => {
    return allPages.find((page: any) => {
      let url = removeUrlAnchor(router.asPath);
      url = removeUrlQueryParams(url);
      return url === page.url ? page : null;
    });
  };

  // Moving getAllPages to cmsTopBarCenterControls to be fetched client-side.
  useEffect(() => {
    const getAllPages = async () => {
      setIsLoading(true);
      const allPages = await getAllCmsPages();
      if (isMounted()) {
        setIsLoading(false);
        dispatch(setAllPages(allPages));
      }
    };

    getAllPages();
  }, [isMounted, dispatch]);

  return (
    <>
      <Box className="page-navigation">
        <PbAutoComplete
          noOptionsText={tCms("no-page-found")}
          fullWidth
          label=""
          onChange={(e, selection: any) => {
            if (selection) {
              if (selection.url === ROOT_PAGE_URL) {
                router.push("/");
              } else {
                router.push("/" + selection.url);
              }
            }
          }}
          defaultValue={getSelectedPage()}
          options={allPages}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              InputLabelProps={{
                style: { color: " " },
              }}
              margin="none"
              InputProps={{
                ...params.InputProps,
                startAdornment: isLoading ? (
                  <>
                    <span
                      className="loading-pages-indicator"
                      style={{ marginLeft: "7px", display: "flex" }}
                    >
                      <PbIcon
                        name="loader-light"
                        width={15}
                        height={15}
                        className={"svg-fill-white rotate"}
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      />
                      <span
                        style={{
                          color: "var(--pb-cms-variable-white)",
                          marginLeft: "5px",
                          display: "flex",
                        }}
                      >
                        {" " + tCms("loadingDotDotDot")}
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    {getSelectedPage()?.url === "/" ? (
                      <span
                        className="default-page-indicator"
                        style={{ marginLeft: "7px", display: "flex" }}
                      >
                        <PbIcon name="globe-light" className="svg-fill-white" />
                      </span>
                    ) : null}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
                {option.url === "/" ? (
                  <span
                    className="default-page-indicator"
                    style={{ display: "flex" }}
                  >
                    <PbIcon name="globe-light" className="svg-fill-white" />
                  </span>
                ) : null}
              </li>
            );
          }}
        />
      </Box>
      <CmsTopBarCenterControlsStyle />
    </>
  );
};

export default CmsTopBarCenterControls;
