import PbInputLabel from "@/components/input/pbInputLabel/pbInputLabel";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

/**
 * Represents the props for the PbAutoComplete component.
 */
export interface PbAutoCompleteProps
  extends AutocompleteProps<any, any, any, any> {
  /**
   * The label for the AutoComplete input.
   */
  label?: string;

  /**
   * Indicates whether the AutoComplete input is mandatory.
   */
  mandatory?: boolean;

  /**
   * An optional additional class name for styling purposes.
   */
  className?: string;

  /**
   * Additional props for the listbox component.
   */
  listboxProps?: any;

  /**
   * The callback function called when the value of the AutoComplete input changes.
   * @param event - The change event.
   * @param value - The new value of the AutoComplete input.
   */
  onChange: (event: any, value: any) => void;
}

/**
 * PbAutoComplete component represents an AutoComplete input field.
 * @param props - The component properties.
 * @returns A JSX element representing the PbAutoComplete.
 */
const PbAutoComplete = (props: PbAutoCompleteProps) => {
  const tCms = useCmsTranslation();
  return (
    <>
      {props.label && (
        <PbInputLabel label={props.label} mandatory={props.mandatory} />
      )}
      <Autocomplete
        noOptionsText={props.noOptionsText || tCms("no-options")}
        freeSolo={props.freeSolo}
        id={props.id ? "cms-autocomplete-id" + props.id : "cms-autocomplete-id"}
        className={`${props.className ? `${props.className}` : ``} ${
          props.multiple ? "cms-autocomplete-multiple" : ""
        }`}
        style={{ width: props.fullWidth ? "100%" : "190px" }}
        multiple={props.multiple}
        value={props.defaultValue || null}
        options={props.options}
        groupBy={props.groupBy}
        onChange={(e, v) => props.onChange(e, v)}
        sx={props.sx ? props.sx : null}
        getOptionLabel={(option) =>
          props.getOptionLabel ? props.getOptionLabel(option) : option.name
        }
        filterSelectedOptions={props.filterSelectedOptions}
        renderOption={props.renderOption}
        size="small"
        aria-owns={props.id ? props.id + "-owns" : ""}
        disableClearable={
          props.disableClearable ? props.disableClearable : false
        }
        disablePortal
        disabled={props.disabled ? props.disabled : false}
        ListboxProps={{
          ...props.listboxProps,
          className: `${
            props.listboxProps?.className || ""
          } MuiAutocomplete-listbox`,
        }}
        renderInput={
          props.renderInput
            ? props.renderInput
            : (params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  margin="none"
                />
              )
        }
      />
    </>
  );
};

export default PbAutoComplete;
