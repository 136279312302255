import { cmsTranslations } from "@/services/cmsTranslation/cmsTranslationService";

export default function useCmsTranslation() {
  const tCms = (key: string, params?: { [key: string]: string }) => {
    let translation = cmsTranslations?.[key] ? cmsTranslations[key] : key;
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        translation = translation.replace("{{" + key + "}}", value);
      }
    }
    return translation;
  };
  return tCms;
}
