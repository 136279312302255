import PbAutoComplete from "@/components/input/pbAutoComplete/pbAutoComplete";
import PbButton from "@/components/input/pbButton/pbButton";
import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbInput from "@/components/input/pbInput/pbInput";
import PbInputLabel from "@/components/input/pbInputLabel/pbInputLabel";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  getAllPagesLocalized,
  getDraftPageRequest,
} from "@/services/pageService/pageService";
import { StrapiUploadFile } from "@/types/strapi";
import {
  LINK_TYPES,
  ROOT_PAGE_URL,
  TARGET_ATTRIBUTES,
} from "@/utils/constants";
import { getLocalizedPagename } from "@/utils/localizationUtil";
import {
  createPersistentDownloadUrlFromFile,
  isPageUrlRootUrl,
  isUrlDownloadUrl,
  sanitizeLink,
} from "@/utils/urlUtil";
import { TextField } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CmsMediaManagerSelector from "../cmsMediaManager/cmsMediaManagerSelector";
import CmsLinkChooserStyle from "./cmsLinkChooserStyle";

export interface CmsLinkChooserProps {
  /**
   * Link Label
   */
  label?: string;
  /**
   * Link URL
   */
  url: string;
  /**
   * URL setter
   */
  onUrlChange: (url: string) => void;
  /**
   * Link rel
   */
  rel: string;
  /**
   * Rel setter
   */
  onRelChange: (rel: string) => void;
  /**
   * Link target
   */
  target: string;
  /**
   * Target setter
   */
  onTargetChange: (target: string) => void;
  /**
   * Link href lang
   */
  hrefLang: string;
  /**
   * Href Lang setter
   */
  onHrefLangChange: (hrefLang: string) => void;
  /**
   * Link get params
   */
  getParams: string;
  /**
   * Get Params setter
   */
  onGetParamsChange: (getParams: string) => void;

  /**
   * Gets called when the link type is
   */
  onLinkTypeChange?: (linkType: "internal" | "external" | "download") => void;

  anchor: string;

  onAnchorChange: (anchor: string) => void;
}

export type Anchor = { anchorName: string; storeType: string; url?: string };

/**
 * CmsLinkChooser
 */
export default function CmsLinkChooser(props: CmsLinkChooserProps) {
  const tCms = useCmsTranslation();
  const router = useRouter();

  const [allPagesLocalized, setAllPagesLocalized] = useState<Array<any>>([]);
  const [anchorsOfSelectedUrl, setAnchorsOfSelectedUrl] = useState<
    Array<Anchor>
  >([]);

  const [linkType, setLinkType] = useState<
    "internal" | "external" | "download" | null
  >(() => {
    if (props.url) {
      if (isUrlDownloadUrl(props.url)) {
        return "download";
      }
      if (props.url.startsWith("/")) {
        return "internal";
      }
      if (
        props.url.startsWith("www") ||
        props.url.startsWith("https") ||
        props.url.startsWith("mailto:")
      ) {
        return "external";
      }
    }
    return "internal";
  });

  const [internalLink, setInternalLink] = useState(() => {
    if (props.url) {
      if (props.url.startsWith("/")) {
        return true;
      }
      if (
        props.url.startsWith("www") ||
        props.url.startsWith("https") ||
        props.url.startsWith("mailto:")
      ) {
        return false;
      }
    } else {
      return true;
    }
  });

  // get all localized published and draftpage anchors for selected url
  useEffect(() => {
    const getLocalizedAnchorsForSelectedUrl = async (url: string) => {
      let anchors: Array<Anchor> = [];

      const getAnchorsFromCEs = (contentElements: Array<any>) => {
        contentElements.forEach((ce) => {
          if (ce.cfgAnchorName) {
            anchors.push({
              anchorName: ce.cfgAnchorName,
              storeType: ce.cfgStrContentElementSetting.storeType,
            });
          }
        });
      };

      const removeDuplicates = () => {
        anchors = anchors.filter((obj, index) => {
          return (
            index === anchors.findIndex((o) => obj.anchorName === o.anchorName)
          );
        });
      };

      const sortAnchorsByName = () => {
        anchors = anchors.sort((a, b) =>
          a.anchorName.localeCompare(b.anchorName)
        );
      };

      for (const page of allPagesLocalized) {
        if ("/" + page.url === url || page.url === url) {
          // get published anchors
          getAnchorsFromCEs(page.content);
          // get possible additional anchors from draft page
          const response = await getDraftPageRequest(page.refId);
          if (response.success) {
            getAnchorsFromCEs(response.data.draftPage.content);
          }
        }
      }

      removeDuplicates();
      sortAnchorsByName();
      setAnchorsOfSelectedUrl(anchors);
    };

    getLocalizedAnchorsForSelectedUrl(props.url);
  }, [props.url, allPagesLocalized]);

  // get all localized pages
  useEffect(() => {
    const fetchAllPagesLocalized = async () => {
      const result = await getAllPagesLocalized();
      if (result.success) {
        setAllPagesLocalized(result.data);
      } else {
        global.log.warn("fetchAllPagesLocalized failed");
      }
    };
    fetchAllPagesLocalized();
  }, [router]);

  const allPageTypesContent = useMemo(() => {
    if (allPagesLocalized.length > 0) {
      return allPagesLocalized.filter((element) => {
        if (router.locale === element.locale) {
          return true;
        }
        return false;
      });
    } else {
      return [];
    }
  }, [allPagesLocalized, router.locale]);

  const handleLinkChange = (newUrl: string) => {
    let sanitizedLink = "";

    if (linkType === "external" && newUrl.length > 0) {
      if (newUrl.startsWith("www.")) {
        sanitizedLink = `https://${newUrl}`;
      } else if (!newUrl.startsWith("http") && !newUrl.startsWith("mailto:")) {
        sanitizedLink = `https://${newUrl}`;
      } else {
        sanitizedLink = newUrl;
      }
    } else {
      sanitizedLink = sanitizeLink(newUrl);
      props.onAnchorChange("");
    }
    props.onUrlChange(sanitizedLink);
  };

  const handleLinkTypeChange = (
    linkType: "internal" | "external" | "download"
  ) => {
    setLinkType(linkType);
    let relContent = "";

    switch (linkType) {
      case "download":
        setInternalLink((prevState) => false);
        props.onUrlChange("");
        props.onAnchorChange("");
        props.onTargetChange("");
        props.onHrefLangChange("");
        props.onGetParamsChange("");
        relContent = props.rel?.includes("nofollow") ? "nofollow" : "";
        break;
      case "external":
        setInternalLink((prevState) => false);
        relContent = props.rel?.includes("nofollow")
          ? "nofollow noopener noreferrer"
          : "noopener noreferrer";
        break;
      case "internal":
      default:
        relContent = props.rel?.includes("nofollow") ? "nofollow" : "";
        setInternalLink((prevState) => true);
        break;
    }

    props.onUrlChange("");
    props.onAnchorChange("");
    props.onRelChange(relContent);
    if (props.onLinkTypeChange) {
      props.onLinkTypeChange(linkType);
    }
  };

  const handleNoFollowChange = (e: any) => {
    let relContent;

    if (e.target.checked) {
      relContent = `nofollow${props.rel ? ` ${props.rel}` : ""}`;
    } else {
      relContent = props.rel.replace(/[\s]*nofollow[\s]*/g, "");
    }

    props.onRelChange(relContent);
  };

  const handleAnchorChange = (e: any, anchor: Anchor) => {
    if (anchor) {
      props.onAnchorChange(anchor.anchorName.replace(/#/g, ""));
    } else {
      props.onAnchorChange("");
    }
  };

  return (
    <>
      <Row className="g-0 cms-link-chooser">
        <PbAutoComplete
          label={`${tCms("linkChooser-linkType")}:`}
          options={LINK_TYPES}
          onChange={(e: any, value: "internal" | "external" | "download") =>
            handleLinkTypeChange(value)
          }
          getOptionLabel={(option) => tCms(`linkChooser-linkType-${option}`)}
          defaultValue={linkType}
          fullWidth
          disableClearable={true}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              InputLabelProps={{
                style: { color: "white" },
              }}
              margin="none"
            />
          )}
        />
        {linkType === "download" && (
          <>
            <Row className="g-0 pt-3">
              <PbInputLabel label={tCms("file-chooseFile") + ":"} lineBreak />
              <CmsMediaManagerSelector
                onConfirm={(file: StrapiUploadFile) => {
                  if (file) {
                    handleLinkChange(createPersistentDownloadUrlFromFile(file));
                    props.onTargetChange("_self");
                  }
                }}
              >
                <PbButton
                  className="file-select-btn"
                  onClick={() => {}}
                  startIcon={
                    <PbIcon
                      name="photo-film-light"
                      className="svg-fill-white"
                      width={20}
                      height={20}
                    />
                  }
                >
                  {tCms("file-chooseFile")}
                </PbButton>
              </CmsMediaManagerSelector>
            </Row>
            {props.url ? (
              <>
                <Row className="g-0 pt-3">
                  <PbInputLabel
                    label={tCms("linkChooser-linkType-download") + ":"}
                    lineBreak
                  />
                </Row>
                <Row className="g-0 pt-3">
                  <Col xs={2}>
                    <div className="mt-2">
                      <PbButton
                        title={tCms("remove")}
                        onClick={() => props.onUrlChange("")}
                        startIcon={
                          <PbIcon
                            name="trash-light"
                            width={20}
                            height={20}
                            className="svg-fill-white"
                          />
                        }
                      ></PbButton>
                    </div>
                  </Col>
                  <Col xs={10}>
                    <div className="download-link">
                      <a href={props.url} download>
                        {props.url}
                      </a>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="g-0 pt-3">
                  <PbInputLabel
                    label={tCms("linkChooser-linkType-download") + ":"}
                    lineBreak
                  />
                </Row>
                <Row className="g-0">
                  <div className="download-link">
                    {tCms("file-noFileChosen")}
                  </div>
                </Row>
              </>
            )}
          </>
        )}
        {linkType === "internal" || linkType === "external" ? (
          <>
            <Row className="g-0 pt-3">
              <PbCheckBox
                label={tCms("linkChooser-nofollow") + ":"}
                withoutColon
                value={props.rel?.includes("nofollow")}
                isSlider={true}
                onChange={(e) => {
                  handleNoFollowChange(e);
                }}
              />
            </Row>
            {internalLink ? (
              <>
                <Row className="g-0 pt-3">
                  <PbAutoComplete
                    fullWidth
                    label={
                      props.label
                        ? props.label
                        : tCms("linkChooser-linkUrl") + ":"
                    }
                    options={allPageTypesContent}
                    groupBy={(option) => tCms(option.groupByLabel)}
                    listboxProps={{
                      className: "link-chooser-popper",
                      style: { maxHeight: "250px" },
                    }}
                    defaultValue={
                      props.url
                        ? allPageTypesContent.find((element: any) => {
                            let anchorlessLink = props.url.split("#");
                            if (
                              anchorlessLink[0] === "/" &&
                              element.url === ROOT_PAGE_URL
                            ) {
                              return element;
                            } else if (
                              `/${element.url}` === anchorlessLink[0]
                            ) {
                              return element;
                            }
                            return null;
                          })
                        : null
                    }
                    disableClearable={true}
                    onChange={(event: any, value: any) => {
                      if (value) {
                        handleLinkChange(value.url);
                      }
                    }}
                    getOptionLabel={(option) => {
                      const pageName = getLocalizedPagename(router, option);
                      return `${pageName} (${sanitizeLink(
                        option?.url ? option.url : option
                      )})`;
                    }}
                    renderOption={(props, option) => {
                      const pageName = getLocalizedPagename(router, option);
                      return (
                        <li {...props} key={option.id}>
                          {`${pageName} (${
                            isPageUrlRootUrl(option.url)
                              ? "/"
                              : "/" + option.url
                          })`}
                          {option.url === "/" ? (
                            <span
                              className="default-page-indicator"
                              style={{ display: "flex" }}
                            >
                              <PbIcon
                                name="globe-light"
                                className="svg-fill-black"
                              />
                            </span>
                          ) : null}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        margin="none"
                      />
                    )}
                  />
                </Row>
                {/* Anchor Dropdown */}
                <Row className="g-0 pt-3">
                  <PbAutoComplete
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        margin="none"
                      />
                    )}
                    disabled={
                      anchorsOfSelectedUrl.length === 0 || props.url === null
                    }
                    fullWidth
                    label={`${tCms("linkChooser-anchor")}:`}
                    options={anchorsOfSelectedUrl}
                    defaultValue={anchorsOfSelectedUrl.find((anchor) => {
                      if (anchor && props.anchor) {
                        return anchor.anchorName === props.anchor;
                      }
                    })}
                    disableClearable={false}
                    onChange={handleAnchorChange}
                    getOptionLabel={(option: Anchor) =>
                      `#${option.anchorName} (${option.storeType})`
                    }
                  />
                </Row>
              </>
            ) : (
              <Row className="g-0 pt-3">
                <PbInput
                  fullWidth
                  label={
                    props.label
                      ? props.label
                      : tCms("linkChooser-linkUrl") + ":"
                  }
                  onChange={(e) => handleLinkChange(e?.target?.value ?? "")}
                  type="text"
                  defaultValue={props.url}
                />
              </Row>
            )}
            <Row className="g-0 pt-3">
              <PbAutoComplete
                label={`${tCms("linkChooser-target")}:`}
                options={TARGET_ATTRIBUTES}
                onChange={(e, v) => props.onTargetChange(v)}
                getOptionLabel={(option) => option}
                defaultValue={props.target}
                fullWidth
                disableClearable={true}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    margin="none"
                  />
                )}
              />
            </Row>
            <Row className="g-0 pt-3">
              <PbInput
                fullWidth
                label={tCms("linkChooser-getParams") + ":"}
                onChange={(e) => props.onGetParamsChange(e.target.value)}
                type="text"
                defaultValue={props.getParams}
              />
            </Row>
            <Row className="g-0 pt-3">
              <PbInput
                fullWidth
                label={tCms("linkChooser-hrefLang") + ":"}
                onChange={(e) => props.onHrefLangChange(e.target.value)}
                type="text"
                defaultValue={props.hrefLang}
              />
            </Row>
          </>
        ) : null}
      </Row>
      <CmsLinkChooserStyle />
    </>
  );
}
