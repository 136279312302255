import {
  authenticateCmsUserRequest,
  forgotPasswordRequest,
  logoutCmsUserRequest,
  renewCmsUserTokenRequest,
  resetPasswordRequest,
} from "@/services/cmsUserService/cmsUserService";
import { createAppAsyncThunk } from "@/store/createAppAsyncThunk";
import { ReduxThunkAction } from "@/store/store";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "../general/generalSlice";
import { logoutAction } from "./cmsUserSlice";

export const authenticateCmsUserThunk = createAppAsyncThunk(
  "cmsUser/authenticateCmsUserThunk",
  async (
    {
      email,
      password,
    }: {
      email: string;
      password: string;
    },
    thunkAPI
  ) => {
    const authResult = await authenticateCmsUserRequest(email, password);
    if (authResult.success) {
      return authResult.data;
    }

    return thunkAPI.rejectWithValue(authResult.error?.message);
  }
);

export const forgotPasswordThunk = createAppAsyncThunk(
  "cmsUser/forgotPasswordThunk",
  async ({ email }: { email: string }) => {
    const forgotPasswordResult = await forgotPasswordRequest(email);
    return forgotPasswordResult.success;
  }
);

export const resetPasswordThunk = createAppAsyncThunk(
  "cmsUser/resetPasswordThunk",
  async (
    {
      resetPasswordToken,
      password,
    }: {
      resetPasswordToken: string;
      password: string;
    },
    thunkAPI
  ) => {
    const resetPasswordResult = await resetPasswordRequest(
      resetPasswordToken,
      password
    );
    if (resetPasswordResult.success) {
      return resetPasswordResult.data.email;
    }
    return thunkAPI.rejectWithValue(resetPasswordResult.error?.message);
  }
);

export const logoutCmsUserThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction("logging out..."));
    const logoutResult = await logoutCmsUserRequest();
    dispatch(hideLoadingOverlayAction());
    if (logoutResult.success) {
      dispatch(logoutAction());
      return Promise.resolve();
    }
    return Promise.reject();
  };

export const refreshCmsUserTokenThunk = createAppAsyncThunk(
  "cmsUser/refreshCmsUserTokenThunk",
  async (_, thunkAPI) => {
    const refreshResult = await renewCmsUserTokenRequest();
    if (refreshResult.success) {
      return refreshResult.data;
    }
    return thunkAPI.rejectWithValue(refreshResult.error?.message);
  }
);
