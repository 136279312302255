const CmsTopBarStyle = (): JSX.Element => {
  return (
    <style jsx global>{`
      .cms-top-bar {
        .page-navigation {
          width: 200px;
          left: calc(50% - 100px);
          display: flex;
          margin: auto;
          justify-content: center;

          @media screen and (min-width: 768px) {
            position: absolute;
            width: 280px;
            left: calc(50% - 140px);
          }
        }
        .cms-top-bar-btn {
          color: white;
          background-color: unset;
          height: 55px !important;
          border-radius: 0px;
          margin: 0px !important;
          padding: 0 20px;
          &:hover {
            background-color: #48515b;
          }
          &.no-text {
            min-width: 35px;
          }
        }

        .page-navigation {
          .MuiInputBase-root {
            background-color: transparent !important;

            input,
            button {
              color: white;
            }
          }

          .MuiPopper-root {
            .MuiAutocomplete-listbox {
              background-color: #2a3035;
              color: white;

              .MuiAutocomplete-option[aria-selected="true"] {
                background-color: #485361;
              }

              .Mui-focused {
                background-color: #434d5a !important;
              }
            }
          }
        }
      }
    `}</style>
  );
};

export default CmsTopBarStyle;
