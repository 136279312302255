import PbInputControlled from "@/components/input/pbInputControlled/pbInputControlled";
import React from "react";
import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import { SelectChangeEvent } from "@mui/material";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import CmsMediaManagerLanguageSelector from "@/components/cms/cmsMediaManager/cmsMediaManagerLanguageSelector";
import { useAppSelector } from "@/store/store";
import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_DOCUMENT,
  MEDIA_TYPE_IMG,
  MEDIA_TYPE_SVG,
  MEDIA_TYPE_VIDEO,
} from "@/utils/constants";

interface CmsMediaManagerFiltersProps {
  onSearchChange: (search: string) => void;
  onMimeTypeChange: (mimeTypes: string) => void;
}

export type MediaManagerFilterOption =
  | typeof MEDIA_TYPE_IMG
  | typeof MEDIA_TYPE_VIDEO
  | typeof MEDIA_TYPE_DOCUMENT
  | typeof MEDIA_TYPE_AUDIO
  | typeof MEDIA_TYPE_SVG;

export default function CmsMediaManagerFilter(
  props: CmsMediaManagerFiltersProps
) {
  const mediaTypes = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.mediaTypes
  );

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.onSearchChange(event.target.value);
  };

  const handleMimeTypeChange = async (event: SelectChangeEvent) => {
    props.onMimeTypeChange(event.target.value);
  };
  const tCms = useCmsTranslation();
  const allFilter: MediaManagerFilterOption[] = mediaTypes;

  return (
    <>
      <div className="media-manager-search">
        <div className="media-manager-search-input">
          <PbInputControlled
            label=""
            fullWidth
            placeholder={"Search"}
            onChange={handleSearchChange}
          ></PbInputControlled>
        </div>
        <div className="media-manager-search-filter">
          <PbDropdown
            dropdownList={allFilter.map((value) => {
              return {
                name: `${tCms("only")} ${tCms(value)}`,
                value: value,
              };
            })}
            emptyValue={tCms("managedfile-no-filter")}
            onChange={handleMimeTypeChange}
            itemNameKey="name"
            itemValueKey="value"
          ></PbDropdown>
        </div>
        <div>
          <CmsMediaManagerLanguageSelector />
        </div>
      </div>
      <style jsx>{`
        .media-manager-search {
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;
        }

        .media-manager-search-input {
          width: 250px;
        }
      `}</style>
    </>
  );
}
