import PbButton from "@/components/input/pbButton/pbButton";
import PbInputControlled from "@/components/input/pbInputControlled/pbInputControlled";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import FilePlaceholderIcon from "@/components/util/filePlaceholderIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  displayNameWithoutFileExt,
  getFileById,
} from "@/services/cmsMediaManager/cmsMediaManagerService";
import { startFileUpload } from "@/services/upload/uploadService";
import {
  mediaManagerModalDiscardDetailView,
  mediaManagerModalRefreshCurrentFolderAction,
  mediaManagerModalSelectFileAction,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { cmsMediaManagerFileUpdateThunk } from "@/store/slices/cmsGeneral/cmsMediaManagerThunks";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { StrapiPbFile } from "@/types/strapi";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { InputAdornment } from "@mui/material";
import { useRouter } from "next/router";
import { useState } from "react";

interface CmsMediaManagerNewFileProps {
  newFile: any;
}

export default function CmsMediaManagerNewFile(
  props: CmsMediaManagerNewFileProps
) {
  const tCms = useCmsTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const selectedLocale = useAppSelector((state) => {
    if (!state.cmsGeneral.mediaManagerModal.locale) {
      return router.locale!;
    }
    return state.cmsGeneral.mediaManagerModal.locale;
  });

  const currentFolder = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.currentFolder
  );

  const [filename, setFilename] = useState<string>(
    displayNameWithoutFileExt(props.newFile?.name)
  );
  const [altText, setAltText] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  const handleCreateEvent = async () => {
    setConfirmButtonDisabled(true);
    if (!filename || filename === "") {
      createToastError(tCms("filenameIsWrong"));
      setConfirmButtonDisabled(false);
      return;
    }

    const fileUploadResult = await startFileUpload({
      file: props.newFile,
      dispatch: dispatch,
      hideLoadingOverlayOnFinish: true,
      overlayMessage: tCms("uploadingFiles"),
      fileNameOverride: `${filename}.${props.newFile.name.substring(
        props.newFile.name.lastIndexOf(".") + 1
      )}`,
      fileInformation: {
        folder: currentFolder?.id,
      },
    });

    if (!fileUploadResult.success) {
      createToastError(tCms("managedFileCreateError"));
      setConfirmButtonDisabled(false);
      return;
    }
    let createdFile;
    if (fileUploadResult.data) {
      createToastSuccess(tCms("managedFileCreateSuccess"));
      dispatch(showLoadingOverlayAction());
      createdFile = await getFileById(fileUploadResult?.data?.id);

      if (createdFile) {
        if (createdFile.pbFile.locale === selectedLocale) {
          createdFile.pbFile.alternativeText = altText;
          createdFile.pbFile.title = title;
          createdFile.pbFile.description = description;
        } else {
          createdFile.pbFile.alternativeText = "";
          createdFile.pbFile.title = "";
          createdFile.pbFile.description = "";
          const newLocalizedPbFile = {
            locale: selectedLocale,
            alternativeText: altText,
            title: title,
            description: description,
          } as StrapiPbFile;
          global.log.info(
            "newLocalizedPbFile " + JSON.stringify(newLocalizedPbFile)
          );
          createdFile.pbFile.localizations.push(newLocalizedPbFile);
        }
        await dispatch(cmsMediaManagerFileUpdateThunk(createdFile));
      }
    }
    dispatch(mediaManagerModalRefreshCurrentFolderAction());
    dispatch(hideLoadingOverlayAction());
    createdFile && dispatch(mediaManagerModalSelectFileAction(createdFile));
    setConfirmButtonDisabled(false);
  };

  return (
    <>
      <div className="media-manager-file-details">
        {props.newFile && (
          <>
            <div className="media-manager-file-preview">
              {props.newFile.type.includes("image") ? (
                <img
                  src={
                    props.newFile?.type?.includes("image")
                      ? URL.createObjectURL(props.newFile)
                      : "/file-placeholder.png"
                  }
                  height={160}
                  alt={tCms("preview-selected-image")}
                />
              ) : (
                <FilePlaceholderIcon
                  mimeType={props.newFile.type}
                  height={120}
                  width={120}
                />
              )}
            </div>
            <hr />
            <div>
              <PbInputControlled
                label={tCms("managedFileModal-name")}
                placeholder={tCms("managedFileModal-name") + "..."}
                fullWidth
                value={filename}
                endAdornment={
                  <InputAdornment position="end">
                    .
                    {props.newFile.name.substring(
                      props.newFile.name.lastIndexOf(".") + 1
                    )}
                  </InputAdornment>
                }
                onChange={(e) => setFilename(e.target.value)}
              />
            </div>
            <div>
              <PbInputControlled
                label={tCms("managedFileModal-altText")}
                placeholder={tCms("managedFileModal-altText") + "..."}
                fullWidth
                value={altText}
                onChange={(e) => {
                  setAltText(e.target.value);
                }}
              />
            </div>
            <div>
              <PbInputControlled
                label={tCms("managedFileModal-title")}
                placeholder={tCms("managedFileModal-title") + "..."}
                fullWidth
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            <div>
              <PbInputControlled
                label={tCms("managedFileModal-description")}
                placeholder={tCms("managedFileModal-description") + "..."}
                fullWidth
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>

            <div className="media-manager-file-action-buttons">
              <PbButton
                disabled={confirmButtonDisabled}
                startIcon={
                  <PbIcon
                    name="floppy-disk-light"
                    height={20}
                    width={20}
                    className="svg-fill-white"
                  />
                }
                onClick={handleCreateEvent}
              >
                {tCms("file-uploadFile")}
              </PbButton>
              <PbButton
                color="secondary"
                startIcon={
                  <PbIcon
                    name="xmark-light"
                    width={20}
                    height={20}
                    className="svg-fill-white"
                  />
                }
                onClick={() => dispatch(mediaManagerModalDiscardDetailView())}
              >
                {tCms("file-discard")}
              </PbButton>
            </div>
          </>
        )}
      </div>
      <style jsx>{`
        .media-manager-file-details {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin: 1rem;
          width: 100%;
        }

        .media-manager-file-preview {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 155px;

          :global(.svg) {
            height: 155px;
            width: 155px;
          }
        }

        .media-manager-file-action-buttons {
          display: flex;
          gap: 1em;
        }
      `}</style>
    </>
  );
}
