export default function CmsTopBarCenterControlsStyle() {
  return (
    <style jsx global>{`
      .page-navigation {
        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          border: 1px solid #1976d2;
          outline: none;

          &:after {
            border: none;
          }
        }

        .MuiInputBase-root.MuiOutlinedInput-root:hover {
          .MuiOutlinedInput-notchedOutline {
            &:after {
              border: 2px solid #1976d2;
            }
          }
        }

        .MuiOutlinedInput-root.Mui-focused
          .MuiOutlinedInput-notchedOutline:after {
          border: 2px solid #1976d2;
        }

        .MuiAutocomplete-popper {
          .MuiAutocomplete-noOptions {
            background-color: #2a3035 !important;
            color: white;
          }
        }
      }
    `}</style>
  );
}
