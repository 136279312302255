import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbInputControlled from "@/components/input/pbInputControlled/pbInputControlled";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import { isPageUrlRootUrl } from "@/utils/urlUtil";
import { useRouter } from "next/router";

export default function CmsPageSettings() {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const page = useAppSelector((state) =>
    state.cmsGeneral.editMode
      ? state.cmsEdit.data?.draftPage
      : state.cmsEdit.data
  );
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const canEditUrl =
    !isPageUrlRootUrl(page?.url) && isLocaleDefaultLocale(router.locale!);

  const getAttributePath = (key: string): string => {
    return editView ? `draftPage.${key}` : `${key}`;
  };

  return (
    <div className="cms-modal-input-wrapper-container">
      <ModalInputWrapper
        label={tCms("name")}
        description={tCms("page-typePageNameHere")}
      >
        <PbInputControlled
          label={""}
          value={page?.name}
          onChange={(e) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("name")}`,
                value: e.target.value,
              })
            );
          }}
          fullWidth
        />
      </ModalInputWrapper>
      {editView && (
        <ModalInputWrapper
          label={tCms("url")}
          description={tCms("page-addPageUrlHere")}
        >
          <PbInputControlled
            fullWidth
            label={""}
            disabled={!canEditUrl}
            value={page.url}
            type="text"
            onChange={(event) =>
              dispatch(
                updateAttributeAction({
                  attributePath: `${getAttributePath("url")}`,
                  value: event.target.value,
                })
              )
            }
          ></PbInputControlled>
        </ModalInputWrapper>
      )}
      <ModalInputWrapper
        label={tCms("page-disableNavigation")}
        description={tCms("page-selectDisabledNavigation")}
      >
        <PbCheckBox
          isSlider
          value={!page?.hasNavigation}
          onChange={(event) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("hasNavigation")}`,
                value: !event.target.checked,
              })
            );
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("page-disableFooter")}
        description={tCms("page-selectDisableFooter")}
      >
        <PbCheckBox
          isSlider
          value={!page?.hasFooter}
          onChange={(event) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("hasFooter")}`,
                value: !event.target.checked,
              })
            );
          }}
          fullHeight
        />
      </ModalInputWrapper>
    </div>
  );
}
