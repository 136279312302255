import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { resetEditDataAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  cmsGetDraftPageAndStartEditModeThunk,
  cmsPublishDraftPageContentThunk,
  cmsSaveDraftPageContentThunk,
} from "@/store/slices/cmsEdit/cmsPageThunks";
import {
  setEditModeAction,
  setEditViewAction,
  showCreatePageModalAction,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { disableBeforeunloadAction, enableBeforeunloadAction, showLoadingOverlayAction } from "@/store/slices/general/generalSlice";
import { redirectThunk } from "@/store/slices/general/generalThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { CMS_PAGES_PATH, CONTEXT_PAGE } from "@/utils/constants";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { useRouter } from "next/router";
import CmsTopBarPublishedIndicator from "./cmsTopBarPublishedIndicator";

const CmsTopBarLeftControlsPage = () => {
  const dispatch = useAppDispatch();
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const currentContext = useAppSelector(
    (state) => state.cmsGeneral.currentContext
  );
  const currentPropsPage = useAppSelector(
    (state) => state.cmsGeneral.currentPropsPage
  );
  const router = useRouter();
  const tCms = useCmsTranslation();

  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(disableBeforeunloadAction());
    dispatch(resetEditDataAction());
    dispatch(setEditModeAction(false));
  });

  const getDraftPageAndStartEditMode = async () => {
    if (router.query.url || router.pathname === "/") {
      let queryUrlStringified: string = router.pathname;
      if (router.query.url) {
        queryUrlStringified = Array.isArray(router.query.url)
          ? router.query.url.join("/")
          : router.query.url;
      }

      try {
        await dispatch(
          cmsGetDraftPageAndStartEditModeThunk(queryUrlStringified)
        );
        return true;
      } catch (error) {
        createToastError("could not load cms page");
      }
    }
    return false;
  };

  const saveDraftpageContent = async () => {
    try {
      await dispatch(cmsSaveDraftPageContentThunk());
      createToastSuccess(tCms("pageSaveSuccess"));
      return true;
    } catch (error) {
      global.log.error(error);
      createToastError(tCms("pageSaveError"));
    }
    return false;
  };

  const publishDraftpageContent = async () => {
    try {
      await dispatch(cmsPublishDraftPageContentThunk());
      createToastSuccess(tCms("pagePublishSuccess"));
      return true;
    } catch (error) {
      createToastError(tCms("pagePublishError"));
    }
    return false;
  };

  const isPagePublished = () => {
    if (!currentPropsPage) {
      return false;
    }
    return currentPropsPage.published && !currentPropsPage.isFallback;
  };

  return (
    <>
      {currentPropsPage && Object.hasOwn(currentPropsPage, "published") && (
        <CmsTopBarPublishedIndicator published={isPagePublished()} />
      )}
      {editMode ? (
        <>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="inherit"
            onClick={async () => {
              const saveSuccess = await saveDraftpageContent();
              if (!saveSuccess) {
                return;
              }
              await getDraftPageAndStartEditMode();
              const publishSuccess = await publishDraftpageContent();
              if (!publishSuccess) {
                return;
              }
              dispatch(disableBeforeunloadAction());
              dispatch(showLoadingOverlayAction(tCms("reloadingPage")));
              dispatch(
                redirectThunk(
                  () => router.reload(),
                  window.location.href,
                  false
                )
              );
            }}
            startIcon={
              <PbIcon
                name="paper-plane-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            title={tCms("publish")}
          >
            {tCms("publish")}
          </PbButton>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            onClick={async () => {
              const saveSuccess = await saveDraftpageContent();
              if (!saveSuccess) {
                return;
              }
              await getDraftPageAndStartEditMode();
            }}
            color="primary"
            startIcon={
              <PbIcon
                name="floppy-disk-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            title={tCms("save")}
          >
            {tCms("save")}
          </PbButton>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            onClick={() => {
              showConfirmModal({
                title: tCms("stopEditing"),
                content:
                  tCms("confirmStopEditing") +
                  " \n " +
                  tCms("unsavedChangesWillBeLost"),
                acceptBtnText: tCms("yes"),
                denyBtnText: tCms("no"),
                icon: "triangle-exclamation-light",
              });
            }}
            color="secondary"
            startIcon={
              <PbIcon
                name="xmark-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            title={tCms("end")}
          >
            {tCms("end")}
          </PbButton>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            onClick={() => {
              dispatch(setEditViewAction(!editView));
            }}
            color="inherit"
            startIcon={
              <PbIcon
                name={editView ? "eye-light" : "eye-slash-light"}
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            title={editView ? tCms("preview") : tCms("editView")}
          >
            {editView ? tCms("preview") : tCms("editView")}
          </PbButton>
        </>
      ) : router.pathname === CMS_PAGES_PATH ? (
        <>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="secondary"
            onClick={async () => {
              dispatch(showLoadingOverlayAction());
              router.push("/");
            }}
            startIcon={
              <PbIcon
                name="angle-left-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            disabled={currentContext === null}
          >
            {tCms("backToWYSIWYG")}
          </PbButton>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="primary"
            onClick={async () => {
              dispatch(showCreatePageModalAction());
            }}
            startIcon={
              <PbIcon
                name="plus-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            disabled={currentContext === null}
          >
            {tCms("page-createPage")}
          </PbButton>
        </>
      ) : (
        <>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="inherit"
            onClick={async () => {
              dispatch(enableBeforeunloadAction());
              await getDraftPageAndStartEditMode();
            }}
            startIcon={
              <PbIcon
                name="pen-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            disabled={currentContext !== CONTEXT_PAGE}
            title={tCms("edit")}
          >
            {tCms("edit")}
          </PbButton>
        </>
      )}
    </>
  );
};

export default CmsTopBarLeftControlsPage;
