import { MediaManagerFilterOption } from "@/components/cms/cmsMediaManager/cmsMediaManagerFilter";

export const SAVE_PAGE_STATE = 1;
export const REVIEW_PAGE_STATE = 2;
export const PUBLISH_PAGE_STATE = 3;

export const ROOT_PAGE_URL = "/";
export const SEARCH_PAGE_URL = "/search";

// placeholder image path
export const IMAGE_PLACEHOLDER = "/image-placeholder.png";

// CMS roles
export const CMS_ROLE_ADMIN = "admin";
export const CMS_ROLE_EDITOR = "editor";
export const CMS_ROLE_AUTHOR = "author";

// CMS PABU Version
export const CMS_PABU_VERSION = "1.1";

// CMS Context type
export const CONTEXT_PAGE = "page";
export const CONTEXT_FORM = "form";
export const CONTEXT_NAVIGATION = "navigation";

export const CMS_FEATURE_PWA = "pwa";

export const TRANSLATION_NAMESPACE_PUBLIC = "public";
export const TRANSLATION_NAMESPACE_CMS = "cms";

// create / edit collection item modal constants for isEdit
export const MODE_CREATE = false;
export const MODE_EDIT = true;

// file sizes
export const MAX_FORM_FIELD_FILE_SIZE_IN_MB = 4;
export const MAX_VIDEO_SIZE = 200 * 1024 * 1024;
export const MAX_AUDIO_SIZE = 20 * 1024 * 1024;

// media types
export const MEDIA_TYPE_IMG = "image";
export const MEDIA_TYPE_YOUTUBE = "youtubeVideo";
export const MEDIA_TYPE_VIDEO = "video";
export const MEDIA_TYPE_AUDIO = "audio";
export const MEDIA_TYPE_DOCUMENT = "document";
export const MEDIA_TYPE_SVG = "svg";

export const CMS_PAGES_PATH = "/cms/pages";

export const DEFAULT_MEDIA_CATEGORIES: MediaManagerFilterOption[] = [
  MEDIA_TYPE_IMG,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_DOCUMENT,
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_SVG,
];

export const LINK_TYPES = ["internal", "external", "download"];
export const TARGET_ATTRIBUTES = ["_self", "_parent", "_top", "_blank"];

/**
 * the chunk fileSize this is not 1MB its a bit above 1MB
 * because the chunks are base64 encoded
 */
export const CHUNK_UPLOAD_DEFAULT_SLICE_SIZE = 1 * (1024 * 1024);
export const CHUNK_UPLOAD_MAX_SLICE_SIZE_IN_MB = 3;

// richtext toolbar options
export const RICHTEXT_MAIN_TOOLBAR = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ align: "" }, { align: "center" }, { align: "right" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ list: "bullet" }, { list: "ordered" }],
  [{ script: "sub" }, { script: "super" }],
  ["link", "clean"],
];

// scaling factor for elements where spaceX/Y scaling is too much
export const MOBILE_SCALING_FACTOR = 0.5;
export const TABLET_SCALING_FACTOR = 0.75;

export const CMS_USER_REFRESH_TOKEN_INTERVAL = 300000;

export const NAVIGATION_MAIN_NAME = "main";

export const MANAGED_FILECATEGORIES = {
  IMAGE: "image",
  VIDEO: "video",
  DOCUMENT: "document",
  AUDIO: "audio",
  SVG: "svg",
};

export const numericDateStringFormat = {
  day: "numeric",
  month: "numeric",
  year: "numeric",
};

export const numericTimeStringFormat = {
  hour: "numeric",
  minute: "numeric",
};

export const REQ_METHOD_GET = "GET";
export const REQ_METHOD_POST = "POST";
export const REQ_METHOD_PUT = "PUT";
export const REQ_METHOD_DELETE = "DELETE";

export const STORE_TYPOGRAPHY_CLASS_PREFIX = "str-typography-";
export const STORE_FONT_CLASS_PREFIX = "str-font-";
export const STORE_COLOR_CLASS_PREFIX = "str-color-";
export const STORE_LINK_CLASS_PREFIX = "str-link-";
export const STORE_BUTTON_CLASS_PREFIX = "str-button-";
export const STORE_BACKGROUND_IMAGE_CLASS_PREFIX = "str-background-";
export const STORE_ARROW_CLASS_PREFIX = "str-arrows-";

export const RICHTEXTEDITOR_DEFAULT_ENABLED_FORMATS = {
  aligncenter: true,
  alignleft: true,
  alignright: true,
  bold: true,
  crossedout: false,
  h1: true,
  h2: true,
  h3: true,
  h4: true,
  h5: true,
  h6: true,
  html: true,
  indentationincrease: true,
  indentationreduce: true,
  italic: true,
  link: true,
  listbullet: true,
  listnumber: true,
  subscript: false,
  superscript: false,
  underlined: true,
  colors: true,
};

export const RICHTEXT_CONTENT_SELECTOR = "pb-richtext";

export const DEFAULT_AS_SELECTABLE_VALUE = -1;




export const ASSET_CONFIG_GLOBAL_JSON = "config-global.json";
export const ASSET_CONFIG_CONTENTELEMENTSETTINGS_JSON = "config-contentelement-settings.json";
export const ASSET_CSS_GLOBAL_MIN_CSS = "css-global.min.css";
export const ASSET_CONTENT_NAVIGATION_JSON = "content-navigations.json";
export const ASSET_CONTENT_DYNAMICLIST_JSON = "content-dynamiclists.json";
export const ASSET_HEAD_HTML = "head.txt";
export const ASSET_BODY_HTML = "body.txt";

export const SUPRESSED_404_ERRORS = [
  ASSET_CONFIG_GLOBAL_JSON,
  ASSET_CONFIG_CONTENTELEMENTSETTINGS_JSON,
  ASSET_CONTENT_NAVIGATION_JSON,
  ASSET_CONTENT_DYNAMICLIST_JSON,
  ASSET_CSS_GLOBAL_MIN_CSS,
  ASSET_HEAD_HTML,
  ASSET_BODY_HTML,
];