import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import useAvailableLocales from "@/hooks/useAvailableLocales";
import { useAppSelector } from "@/store/store";
import { changeLanguage } from "@/utils/localizationUtil";
import { SelectChangeEvent } from "@mui/material";
import { useRouter } from "next/router";
import LanguageSelectorStyle from "./languageSelectorStyle";

export default function LanguageSelector() {
  const router = useRouter();
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const availableLocales = useAvailableLocales();
  const localesInSync = useAppSelector(
    (state) => state.cmsGeneral.localesStatus.localesInSync
  );
  return (
    <>
      <PbDropdown
        className="language-selector"
        disabled={editMode || !localesInSync}
        dropdownList={availableLocales}
        onChange={(e: SelectChangeEvent) =>
          changeLanguage(e.target.value, router)
        }
        value={router.locale}
        itemNameKey={"name"}
        itemValueKey={"value"}
      ></PbDropdown>
      <LanguageSelectorStyle />
    </>
  );
}
