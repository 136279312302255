import CmsMaxLengthIndicator from "@/components/cms/cmsMaxLengthIndicator/cmsMaxLengthIndicator";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import { InputAdornment, OutlinedInputProps } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTranslation } from "next-i18next";
import { useRef } from "react";
import PbInputLabel from "../pbInputLabel/pbInputLabel";

/**
 * Represents the props for the PbInput component.
 */
export interface PbInputProps extends OutlinedInputProps {
  /**
   * The label for the input.
   */
  label?: string;

  /**
   * The maximum length allowed for the input.
   */
  maxLength?: number;

  /**
   * Indicates whether the input is mandatory.
   */
  mandatory?: boolean;

  /**
   * Indicates whether to hide the length indicator.
   */
  hideLengthIndicator?: boolean;

  /**
   * Specifies the distance from the right for displaying the length indicator.
   */
  showMaxLengthIndicatorRight?: string;

  /**
   * The error text to display.
   */
  errorText?: string;

  /**
   * The callback function called when the value of the input changes.
   * @param event - The change event.
   */
  onChange?: (event: any) => void;
  tooltip?: string;
  type?: string;
  onKeyDown?: (event: any) => void;
}

/**
 * PbInput component represents an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the PbInput.
 */
const PbInput = (props: PbInputProps) => {
  const { t: tPublic } = useTranslation("public");

  const maxLength = props.maxLength || 250;
  const inputWithMaxLength = useRef<any>();

  return (
    <>
      {props.label && (
        <PbInputLabel
          label={props.label}
          mandatory={props.mandatory}
          tooltip={props.tooltip}
        />
      )}
      <div className="with-max-length-indicator">
        <OutlinedInput
          onKeyDown={(event) => props.onKeyDown && props.onKeyDown(event)}
          label={props.label ?? " "} // the whitespace string is needed so the notched=false attribute works
          notched={false}
          multiline={props.multiline}
          fullWidth={props.fullWidth}
          sx={props.sx && null}
          className={
            props.error
              ? "cms-input-error cms-collection-type-text"
              : "cms-collection-type-text"
          }
          type={props.type ?? "text"}
          name={props.name ?? ""}
          defaultValue={props.defaultValue}
          onChange={(event) => {
            props.onChange && props.onChange(event);
          }}
          placeholder={props.placeholder ?? ""}
          autoFocus={props.autoFocus}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          inputProps={{ maxLength: `${maxLength}` }}
          inputRef={inputWithMaxLength}
          error={props.error}
          minRows={props.minRows && ""}
          endAdornment={
            props.endAdornment ? (
              <>
                {props.endAdornment}
                {props.error ? (
                  <InputAdornment position="end">
                    <div className="input-error-icon">
                      <PbIcon
                        name="triangle-exclamation-light"
                        className="svg-fill-red"
                        width={20}
                        height={20}
                      />
                    </div>
                  </InputAdornment>
                ) : null}
              </>
            ) : (
              <>
                {props.error ? (
                  <InputAdornment position="end">
                    <div className="input-error-icon">
                      <PbIcon
                        name="triangle-exclamation-light"
                        className="svg-fill-red"
                        width={20}
                        height={20}
                      />
                    </div>
                  </InputAdornment>
                ) : null}
              </>
            )
          }
          disabled={props.disabled ? props.disabled : false}
          onKeyUp={props.onKeyUp ? props.onKeyUp : (e) => {}}
        />

        {!props.hideLengthIndicator && (
          <CmsMaxLengthIndicator
            right={props.showMaxLengthIndicatorRight ? "-51px" : "0px"}
            maxLength={props.maxLength}
            length={inputWithMaxLength.current?.value?.length}
          />
        )}
      </div>
      {props.error && (
        <div className="cms-input-error-msg">
          {props.errorText ? props.errorText : tPublic("inputIncorrect")}
        </div>
      )}
      {/*<CmsInputStyle />*/}
    </>
  );
};

export default PbInput;
