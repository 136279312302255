import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { StrapiUploadFile, StrapiUploadFileLocalization } from "@/types/strapi";
import { getStrapiURLClientSide } from "@/utils/api";
import {
  imageFormat,
  lowerBreakpointDesktopPx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import clsx from "clsx";
import { CSSProperties, ReactEventHandler } from "react";

/**
 * Interface for the CmsImageProps used in the CmsImage component.
 */
export interface CmsImageProps {
  /**
   * The image file to be displayed in the CMS image component.
   */
  image: StrapiUploadFile;

  /**
   * The optional format for displaying the image.
   */
  format?: ImageFormat;

  /**
   * The locale for the alt-text and title.
   */
  locale?: string;

  /**
   * The alternative text for the image (for accessibility).
   */
  alt?: string;

  /**
   * The title of the image.
   */
  title?: string;

  /**
   * The width of the image.
   */
  width?: string | number;

  /**
   * The height of the image.
   */
  height?: string | number;

  /**
   * The CSS class(es) for the image component.
   */
  className?: string;

  /**
   * The object fit of the image ('cover', 'contain', or 'fill').
   */
  objectFit?: string;

  /**
   * The object position of the image.
   */
  objectPosition?: string;

  /**
   * Responsive image settings for different device sizes.
   */
  responsive?: ImageResponsiveSettings;

  /**
   * Represents the styling properties for a component.
   */
  style?: CSSProperties | undefined;

  /**
   * Triggers a function call on load
   */
  onLoad?: ReactEventHandler<HTMLImageElement> | undefined;

  /**
   * Sets a ref on the image
   */
  imageRef?: any;
}

export type ImageFormat = "thumbnail" | "small" | "medium" | "large";

export type ImageResponsiveSettings = {
  mobile?: number;
  tablet?: number;
  desktop?: number;
  wqhd?: number;
};

const localizationByLocal = (
  file: StrapiUploadFile,
  local: string | undefined
) => {
  if (!file.localizations || (local && !file.localizations?.[local])) {
    return {
      ...file,
      isStrapiDefault: false,
    } as StrapiUploadFileLocalization;
  }

  return local && file.localizations?.[local]
    ? file.localizations[local]
    : localizationStrapiDefault(file);
};

const localizationStrapiDefault = (
  file: StrapiUploadFile
): StrapiUploadFileLocalization => {
  const defaultLocalization = Object.values<StrapiUploadFileLocalization>(
    file.localizations || {}
  ).find((value) => value.isStrapiDefault);
  return (
    defaultLocalization ??
    ({
      ...file,
      isStrapiDefault: false,
    } as StrapiUploadFileLocalization)
  );
};

export default function CmsImage(props: CmsImageProps) {
  const format = imageFormat(props.image, props.format);
  const localization = localizationByLocal(props.image, props.locale);
  return (
    <>
      <img
        ref={props.imageRef}
        className={clsx(
          "cms-image",
          {
            start: props.objectPosition === "left",
          },
          { end: props.objectPosition === "right" },
          props.className
        )}
        src={getStrapiURLClientSide(format.url)}
        style={props.style}
        alt={props.alt ?? localization.alternativeText ?? ""}
        title={props.title ?? localization.title ?? ""}
        width={props.width ?? format.width}
        height={props.height ?? format.height}
        onLoad={props.onLoad}
      />
      <style jsx>{`
        .cms-image {
          object-fit: ${props.objectFit ?? "contain"};
          object-position: ${props.objectPosition ?? "unset"};

          @media screen and (max-width: ${globalConfig?.responsive?.breakpoints
              ?.mobile}px) {
            max-height: ${props.responsive?.mobile
              ? `${props.responsive.mobile}px`
              : "none"};
          }
          @media screen and (min-width: ${lowerBreakpointTabletPx()}) {
            max-height: ${props.responsive?.tablet
              ? `${props.responsive.tablet}px`
              : "none"};
          }
          @media screen and (min-width: ${lowerBreakpointDesktopPx()}) {
            max-height: ${props.responsive?.desktop
              ? `${props.responsive.desktop}px`
              : "none"};
          }
          @media screen and (min-width: ${lowerBreakpointWqhdPx()}) {
            max-height: ${props.responsive?.wqhd
              ? `${props.responsive.wqhd}px`
              : "none"};
          }
        }

        .cms-image.start {
          margin-left: 0;
          margin-right: auto;
        }

        .cms-image.end {
          margin-left: auto;
          margin-right: 0;
        }
      `}</style>
    </>
  );
}
